import logo from './logo.svg';
import './App.css';

function App() {
  const sendMessageToInAppBrowser = (message) => {
    const messageObj = {message}
    const stringifiedMessageObj = JSON.stringify(messageObj)
    if (window.webkit && window.webkit.messageHandlers) {
      console.log('postmessage call on webkit')
      window.webkit.messageHandlers.cordova_iab.postMessage(stringifiedMessageObj)
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <button onClick={() => sendMessageToInAppBrowser('Hello World')}>Post Message</button>
        <p>
          Test Auto Deploy with Post messages
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React 2
        </a>
      </header>
    </div>
  );
}

export default App;
